<script>
import InfoBoxForm from "@/views/info-box/infoBoxForm";
import Component, { mixins } from "vue-class-component";
import { Utility } from "@/lib/Utility";

@Component({
  inject: {
    infoBoxService: "infoBoxService",
    eventService: "eventService",
    toastService: "toastService"
  },
})
export default class InfoBox_edit extends mixins(InfoBoxForm) {
  formId = "edit-infoBox-form";

  get queryParameters() {
    return this.$route.params;
  }

  get fieldColumns() {
    return 2;
  }
  get actions() {
    return [
      {
        id: "save",
        type: "submit",
        text: this.translations.labels.button_save,
        leftIcon: "mdi-content-save-outline",
        handle: this.save,
      },
    ];
  }
  async save(action, passesValidation) {
    this.errors = [];
    if (!passesValidation) {
      return;
    }
    try {
      let cleanModel = Utility.clone(this.model);
      cleanModel.x = +this.model.x;
      cleanModel.y = +this.model.y;
      cleanModel.height = +this.model.height;
      cleanModel.width = +this.model.width;

      await this.infoBoxService.update(this.model.id,cleanModel);
      this.toastService.success(this.translations.success.infoBox_update);
      this.$router.push(`/info-box/list/${this.queryParameters.idParent}`);
    } catch (error) {
      this.errors = this.formatApiErrors(error);
      this.toastService.error(this.translations.errors.somethingWrong);
    }
  }

  getFormTitle() {
    return this.translations.pageTitles.infoBox_edit;
  }

  async afterLoad() {
    this.model = await this.infoBoxService.read(this.$route.params.id);
    this.isReady = true;
  }
}
</script>
